.card-main{
	overflow: hidden;
	height: 450px;
	box-shadow: 2px 2px 5px #00000020;
}
.image-container{
	overflow: hidden;
}
.card-image{
    transition: all 0.5s ease-in-out;
    height: 200px;
    width: 100%;
    object-fit:cover;
}
.card-main:hover{
	box-shadow: 3px 3px 5px #00000040;
	cursor: pointer;
}
.card-main:hover .card-image{
	transform: scale(1.1);
	cursor: pointer;
}

.card-main.card:hover .hover-bg-img{
	background-position: center bottom !important;
}

/* .card-container{
	background-image: url('./../../../images/svg/description-block-image.svg');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
} */

.card{
	background-color: #ECF0FA;
	transition: all .3s ease-out;
}