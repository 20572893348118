.contact_bg {
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
        url("../../images/contact.jpg");
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

/* .contact-container{
    background-image: url('./../../images/svg/section-numbers-back.svg');
} */

/* contact form  */
.input_field{
    border-radius: 10px;
    border: 1px solid gray;
    outline: none;
}

.input_field:hover{
    background-color: #c1d0ff;
}

.input_field:focus{
    background-color: white;
    -webkit-box-shadow: 0px 10px 13px -7px #000000, inset 11px -17px 22px -2px rgba(0,0,0,0.19); 
box-shadow: 0px 10px 13px -7px #000000, inset 11px -17px 22px -2px rgba(0,0,0,0.19);
}


.submit_button{
    color: white;
    background-color: #262340;
    border: none;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
}

.submit_button:hover{
    background-color: #5371cd;
}

/* contact form end */
.phone{
    /* background-color: #262340; */
    background-color: #ECF0FA;
}

.phone, .email, .office-address{
    border-radius: 15px;
}

/* .phone h3, .email h3{ */
    /* color: white; */
/* } */

.phone_number, .email_address{
    text-decoration: none;
    color: #262340;
    font-size: 16px;
    /* font-weight: bold; */
}

.phone_number:hover, .email_address:hover{
    /* color: white; */
    color: #3e3a61;
}

.email{
    /* background-color: #262340; */
    background-color: #ECF0FA;
}

.office-address{
    /* background-color: #262340; */
    color: black;
}

.emailIcon, .phoneIcon{
    /* color: rgb(172, 172, 255); */
    color: #262340;
    font-size: 25px;
}

.locationIcon{
    font-size: 40px;
    color: #262340;
}