/* search menu start */
.search-menu {
  position: relative;
  padding: 1rem 0;
}
.search-menu:hover .search-menu-container {
  display: flex;
  gap: 2rem;
  z-index: 999;
}

.search-menu-container {
  position: absolute;
  top: 100%;
  width: 300px;
  height: 70px;
  margin-left: -265px;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  display: none;
  padding: 1rem 2rem;
  border-radius: 5px;
}

form {
  color: #555;
  display: flex;
  padding: 2px;
  border: 1px solid currentColor;
  border-radius: 5px;
}

input[type="search"] {
  border: none;
  background: transparent;
  margin: 0;
  padding: 7px 8px;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
}

input[type="search"]::placeholder {
  color: #bbb;
}

.search-btn {
  text-indent: -999px;
  overflow: hidden;
  width: 40px;
  padding: 0;
  margin: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat center;
  cursor: pointer;
  opacity: 0.7;
}

.search-btn:hover {
  opacity: 1;
}

.search-btn:focus,
input[type="search"]:focus {
  box-shadow: 0 0 3px 0 #1183d6;
  border-color: #1183d6;
  outline: none;
}

.nav-link {
  color: #333333 !important;
}

/* .my-nav a {
    margin: 0 10px;
  } */

.my-nav a {
  transition: all 0.3s linear;
  position: relative;
  font-weight: 500;
}
.my-nav a::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  transition: all 0.3s ease-out;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #245D7B;
}

.my-nav a:hover {
  color: #245D7B !important;
}
.my-nav a:hover::after {
  width: 100%;
  height: 2px;
}

.active a {
  border-bottom: 3px solid #181174;
}

.navbar-brand:hover {
  border-bottom: none !important;
}
/* ------------------------------ */
.bg-white {
  background-color: white !important;
  color: rgb(46, 46, 46) !important;
}
.bg-transparent {
  /* background-color: transparent; */
  /* background-color: rgba(39, 39, 39, 0.427) !important; */
  color: white !important;
}

.text-color {
  color: rgb(183, 183, 183);
}

.box-shadow {
  box-shadow: 0px 0.2rem 0.4rem rgb(0 0 0 / 6%),
    0px 0.4rem 0.6rem rgb(0 0 0 / 10%);
}
