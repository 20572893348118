.card.profile-card {
  text-align: center;
  background: transparent;
  opacity: 1;
  position: relative;
  padding: 30px;
  margin-bottom: 50px;
  min-height: 469.8px;
  border: none;
  box-shadow: 3px 3px 10px #0000ff80;
}

.marginTopMinus{
  margin-top: -140px;
}

.img-bg {
  height: 400px;
  background-image: url("//images03.nicepage.com/c461c07a441a5d220e8feb1a/6ba6351e480757c3a03abdad/fgd-min.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.card.profile-card::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #111111;
  background-color: #ffffff;
  opacity: 0.9;
}

.card-img-top {
  width: 240px;
  height: 240px;
  margin: 0 auto;
  z-index: 2;
  border-radius: 50%;
}

.card-body {
  margin-top: 20px;
  margin-bottom: 0;
  z-index: 2;
}
.card-body h5 {
  text-transform: uppercase;
  font-size: 1.5rem;
}
.card-body p {
  text-align: center;
  color: #478ac9 ;
  font-weight: 700;
  margin-top: 12px;
  margin-bottom: 0;
}

.card-description {
  font-style: italic;
  margin-top: 23px;
  margin-bottom: 0;
  z-index: 2;
}

.card-description p {
  text-align: center;
}

.card-social{
    z-index: 2;
    margin-top: 20px;
}

@media (max-width: 768px) {
  .marginTopMinus{
    margin-top: 0;
  }
  
}
