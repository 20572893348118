.career_bg {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../../images/career-ladder.jpg");
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.DetailsBtn {
  outline: none;
  border: none;
  margin-top: 15px;
  padding: 10px 15px;
  background-color: rgba(0, 0, 255, 0.856);
  color: white;
}

.DetailsBtn:hover {
  background-color: blue;
}

.slider_title_border {
  height: 4px;
  width: 150px;
  background-color: #13007b;
}