.pageNotFoundWrapper{
    height: 100vh;
    width: 100%;
    background: url(./../../images/404.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size:contain;
}

.backToHomeBtn{
    position:absolute;
    bottom: 50px;
}