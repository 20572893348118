.single_card {
  height: 230px;
}

.product_bg {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../../images/code.jpg");
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.image_style {
  transition: transform 0.5s ease;
}

.image_style:hover {
  transform: scale(1.1);
}

@media (max-width: 576px) {
  .single_card {
    height: auto;
  }
}
