.service_bg {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../../images/businessman.jpg");
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.service_bg h2 {
  font-size: 2.5rem;
  font-weight: bolder;
}

.card-image{
  width: 100%;
}