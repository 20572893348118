.developmentModels-wrapper{
    background-color: rgb(2, 2, 137);
    color: white;
}

.developmentModels-wrapper h2{
    text-align: center;
}

.model-menu{
    display: flex;
    justify-content: space-evenly;
    border-bottom: 2px solid rgb(190, 190, 190);
    padding: 10px 0;
}

.model-menu a{
    display: inline-block;
    text-decoration: none;
    color: rgb(190, 190, 190);
    font-size: 20px;
    font-weight: bold;
}

.model-menu a:hover{
    color: white;
}

.life-cycle{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.life-cycle img{
    width: 80%;
    margin: 100px 0;
}


.font-with-bg-color{
    background-color: rgb(2, 2, 137) !important;
    color: white !important;
}