.hire-area {
  background: linear-gradient(#141e30, #243b55);
}

.reg-form-wrapper {
  padding: 40px;
  background: #00000080;
}

.form-input-group {
  margin-bottom: 30px;
  color: #fff;
}

.section-title-join {
  margin-bottom: 40px;
  color: #fff;
}

.login-box .btn-submit {
  background: transparent;
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #03e9f4;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 20px;
  letter-spacing: 4px;
}

.login-box .btn-submit:hover {
  border-color: transparent;
  background: #03e9f4;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4,
    0 0 100px #03e9f4;
}

.login-box .btn-submit span {
  position: absolute;
  display: block;
}

.login-box .btn-submit span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #03e9f4);
  animation: btn-anim1 1s linear infinite;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}

.login-box .btn-submit span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #03e9f4);
  animation: btn-anim2 1s linear infinite;
  animation-delay: 0.25s;
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}

.login-box .btn-submit span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
  animation: btn-anim3 1s linear infinite;
  animation-delay: 0.5s;
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

.login-box .btn-submit span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
  animation: btn-anim4 1s linear infinite;
  animation-delay: 0.75s;
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .reg-form-wrapper {
    padding: 30px 10px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .reg-form-wrapper {
    padding: 30px 10px;
  }
}
@media screen and (max-width: 767px) {
  .reg-form-wrapper {
    padding: 12px 0;
  }
}
