.about_bg {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../../images/about.jpg");
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.sub-heading{
  font-size: 25px;
}