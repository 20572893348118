@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  width: 100%;
  min-height: 100vh;
}

img{
  max-width: 100%;
}

.navbar {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}

p {
  font-size: 16px;
  text-align: justify;
}

button.slick-arrow.slick-prev,
button.slick-arrow.slick-next {
  display: none !important;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  padding-left: 15px;
  padding-right: 15px;
}

@media (max-width: 991px) and (min-width: 768px) {
  .newsletter h3 {
    font-size: 18px;
  }

  .card {
    margin-top: 0 !important;
  }
}

@media (max-width: 767px) and (min-width: 426px) {
  .card {
    margin-top: 0 !important;
  }
}

@media (max-width: 426px) {
  span.input-group-btn {
    width: 50px;
  }

  button.d-md-none.d-block {
    height: 40px;
    border: 1px solid #256d7b;
    background: #256d7b;
    border-radius: 0 10px 10px 0;
  }

  .card {
    margin-top: 0 !important;
  }
}
