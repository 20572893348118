.footerWrapper{
  background-color: #ECF0FA;
  color: black;
}

.service-item{
  text-decoration: none;
  color: black;
  transition: all .3s ease-out;
}

.service-item:hover{
  /* color: white; */
  color: #234D7B;
}

.instagramColor{
  background: linear-gradient(29.61deg, #f38334 0%, #da2e7d 50.39%, #6b54c6 100%);
  color: white;
}