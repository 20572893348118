.bussiness-contain {
  /* height: 455px; */
  width: 1240px;
  margin-bottom: 50px;
}

.threedconversition {
	transform: translateZ(0) scale(1);
	position: relative;
    margin: 20px;
    box-shadow: 20px 20px 50px rgb(0 0 0 / 50%);
    border-radius: 0px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
}

.certificate-container {
  height: 182px;
  width: 354px;
  border: 2px solid #13007b;
  margin: 10px;
  padding: 10px;
}

.client-container {
  height: 150px;
  width: 200px;
  border: 1px solid #13007b;
  margin: 10px;
  padding: 10px;
}

.client-container img {
  height: 100px;
  width: 120px;
  object-fit: contain;
  margin: 5px auto;
}

.newsletter {
  padding: 80px;
  background-color: #262340;
  color: #ffffff;
}

@media (max-width: 426px) {
  .newsletter {
    padding: 80px 0;
  }
}

.newsletter .form-control {
  height: 40px;
  border-color: #fff;
  border-radius: 10px;
}

.newsletter .form-control:focus {
  box-shadow: none;
}

.newsletter .btn {
  min-height: 41px;
  border-radius: none;
  background-color: #243c4f;
  color: #fff;
  font-weight: "600";
}

/* --------------------------------------- */
/* .card-main{
	overflow: hidden;
	height: 450px;
}
.card-image{
	transition: all 0.5s ease-in-out;
}
.card-main:hover{
	box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.card-main:hover .card-image{
	transform: scale(1.1);
	overflow: hidden;
	cursor: pointer;
} */
.titleIcon::after {
  content: "";
  width: 40px;
  height: 4px;
  background-color: red;
  display: block;
}

.extra-border {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.extra-border p {
  text-align: center;
  width: 50px;
  height: 3px;
  background: rgb(195, 2, 2);
  border-radius: 5px;
  margin: 0;
}

.arrow-icon {
  display: flex;
  justify-content: flex-end;
}

.arrow-icon img {
  /* display: none; */
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s linear;
}

.card-main:hover .arrow-icon img {
  /* display: block; */
  visibility: visible;
  cursor: pointer;
  opacity: 1;
}

.client-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  width: 200px;
  height: 100px;
}

.client-slide-img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
  background-color: red;
}

.load-more {
  text-decoration: none;
  background-color: #3662e4;
  color: #fff;
  border-radius: 5px;
}

.load-more:hover {
  color: #fff !important;
  /* border: 1px solid #1e00c9 !important; */
}
.load-more:hover svg {
  -webkit-transform: translateX(4px);
  transform: translateX(4px);
}
.load-more svg {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

.productsBG {
  /* background-image: url('./../../../images//svg/custom-wave-2.svg'); */

  /* background: url('./../../../images/svg/section-numbers-back.svg'); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
}

.productsServiceContainer {
  background: url("./../../../images/svg/section-numbers-back.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
}

.textShadow {
  text-shadow: 2px 2px 2px #ceb9b8;
}
