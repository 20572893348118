.burger {
  font-size: 60px;
  color: #fbaf32;
  font-weight: bold;
  font-family: "Nunito", sans-serif;
}

.custom-btn {
  padding: 12px 25px;
  font-size: 16px;
  font-weight: 800;
  color: #fff;
  background: rgb(155, 5, 255);
  border: 2px solid rgb(155, 5, 255);
  border-radius: 5px;
  transition: 0.4s;
}

.carousel-item {
  height: 100vh;
  background: no-repeat scroll center scroll;
  background-size: cover;
  background-attachment: fixed;
}

.carousel-caption {
  margin-bottom: 120px;
}

.carousel-item::before {
  position: absolute;

  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  opacity: 0.7;
}

.carousel-caption h1 {
  font-family: "Nunito", sans-serif;
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 30px;
  text-align: center;
}

.carousel-caption p {
  color: #fff;
  text-align: center;
  font-size: 20px;
  margin-bottom: 35px;
}

.carousel-caption span {
  color: rgb(155, 5, 255);
}

.custom-btn:last-child {
  margin-left: 10px;
  background: rgb(155, 5, 255);
  border-color: rgb(155, 5, 255);
}

.custom-btn:hover {
  border: 2px solid rgb(155, 5, 255);
  color: rgb(155, 5, 255);
  border-radius: 5px;
  background-color: transparent;
}

.custom-btn:hover:last-child {
  color: rgb(155, 5, 255);
  border: 2px solid rgb(155, 5, 255);
  border-radius: 5px;
}

.right-slide-in {
  animation: right-animation-in 0.5s ease-in;
}

@keyframes right-animation-in {
  from {
    transform: translateX(200px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}

.left-slide-in {
  animation: left-animation-in 0.5s ease-in;
}

@keyframes left-animation-in {
  from {
    transform: translateX(-200px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}

/* ---------------------------- */
.banner-wrapper {
  height: 750px;
  width: 100%;
  /* background-image: url('./../../../images/bannerBg.jpg'); */
  /* background-color: #002842; */
  /* background: rgb(238,174,202);
  background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%); */

  /* background: rgb(14,55,142); */
  /* background: linear-gradient(0deg, rgba(14,55,142,1) 0%, rgba(43,199,247,1) 95%); */

  background-color: #24265d;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.banner-wrapper video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}
.banner-contents {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.checkMark {
  background-color: #0344894b;
  font-size: 30px;
  color: white;
  padding: 5px;
  border-radius: 50%;
}

.banner-subtitle {
  color: #0e89fb;
}

.bannerContents {
  width: 50%;
  margin-top: 100px;
}
.bannerSlider {
  width: 50%;
}

.slideItem {
  height: 300px;
  width: 100%;
}

.redText {
  color: rgba(146, 0, 0, 0.796);
}

.quotation {
  color: #22a7e1;
}
.one {
  background-color: #53cbfe56;
}
.two {
  background-color: #019bdd40;
}
.three {
  background-color: #588fa552;
}
.four {
  background-color: #1f497d48;
}
.five {
  background-color: #0096aa56;
}
.six {
  background-color: #a7ccdf52;
}

.video {
  width: 100% !important;
  height: 478px !important;
}

.react-player__shadow {
  background: radial-gradient(#ffdb52, #88888880 60%) !important;
}
.react-player__shadow:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 64px;
  height: 64px;
  background: #ff4c60;
  border-radius: 50%;
  transition: all 200ms;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

.react-player__shadow {
	-webkit-animation: scale-up-center 1s cubic-bezier(0.680, -0.550, 0.265, 1.550) both;
	        animation: scale-up-center 1s cubic-bezier(0.680, -0.550, 0.265, 1.550) both;
}

/* .react-player__shadow  {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
} */
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.react-player__shadow::before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 64px;
  height: 64px;
  background: #ff4c60;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}
.react-player__play-icon {
  border-color: transparent transparent transparent #fff !important;
  border-width: 10px 0px 10px 18px !important;
  z-index: 899;
}

@media screen and (min-width: 1201px) and (max-width: 1500px) {
  .banner-wrapper {
    height: 750px;
  }

  .video {
    width: 100% !important;
    height: 409px !important;
  }
}
@media screen and (min-width: 994px) and (max-width: 1200px) {
  .video {
    width: 100% !important;
    height: 409px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .banner-wrapper {
    height: 700px;
  }
  .bannerContents {
    width: 100%;
  }
  .bannerSlider {
    padding-left: 0 !important;
    width: 100%;
    display: flex;
    align-items: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrapper {
    height: 670px;
  }

  .video {
    width: 100% !important;
    height: 390px !important;
  }
  /*  .banner-wrapper{
    height: 110vh;
  }
  .bannerSlider {
    padding-left: 0px !important;
    margin-top: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }*/
}

@media only screen and (max-width: 767px) {
  .banner-wrapper {
    height: 520px;
}

  .video {
    width: 100% !important;
    height: 195px !important;
  }

  .btn-1,
  .btn-2 {
    width: 100%;
    margin: 4px 0;
  }

  .bannerSlider {
    padding-left: 0px !important;
    margin-top: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
